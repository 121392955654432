<template>
  <div class="content__body">
    <div class="content__title content__title--party">
      <div class="table-limiter">
        <div class="table-limiter__text">Количество записей на странице:</div>
        <div class="table-limiter__select">
          <dropdown class="my-dropdown-toggle"
                    :options="limitOptions"
                    :selected="limitSelected"
                    :closeOnOutsideClick="true"
                    @updateOption="updateLimiter"
          >
          </dropdown>
        </div>
      </div>
      <div class="table-limiter">
        <div class="table-limiter__text">
          Показаны записи <span>{{ returnEnd() > 0 ? returnStart() : 0 }} - {{ returnEnd() }}</span> из <span>{{ pagination.count }}</span>
        </div>
      </div>
    </div>
    <v-client-table
      @row-click="onRowClick"
      :data="table.items"
      :columns="table.columns"
      :options="table.options"
      ref="logsListTable"
      class="table-default table-default--dynamic">
      <template slot="parse_time" slot-scope="option">
        {{ toNormalDateTime(option.row.parse_time) }}
      </template>
    </v-client-table>
    <paginate
      v-if="pagination.pages > 1"
      v-model="pagination.page"
      :page-count="pagination.pages"
      :clickHandler="clickPaginationCallback"
      :prev-text="'<'"
      :next-text="'>'"
      :container-class="'pagination'"
      :page-class="'pagination__item'"
      :page-link-class="'pagination__link'"
      :prev-class="'pagination__prev'"
      :next-class="'pagination__next'"
      :next-link-class="'pagination__link-next'"
      :prev-link-class="'pagination__link-prev'"
    >
    </paginate>
  </div>
</template>

<script>
import dropdown from 'vue-dropdowns'

export default {
  name: 'StatisticsList',
  components: { dropdown },
  data() {
    return {
      stats: {},
      table: {
        items: [],
        columns: ['id', 'parse_time', 'errors_count'],
        options: {
          headings: {
            'id': 'ID',
            'parse_time': 'Время',
            'errors_count': 'Ошибок',
          },
          pagination: { show: false },
          sortable: [],
          perPage: 20,
          texts: {
            filter: '',
            filterPlaceholder: 'Поиск по таблице',
            noResults: 'Нет подходящих записей',
            filterBy: '',
            loading: 'Загрузка',
            count: '',
            limit: 'Количество записей на странице:',
            page: 'Страница:'
          },
          rowClassCallback: () => 'table-default__row',
        },
      },
      pagination: {
        pages: 1,
        page: 1,
        count: 0,
        prevLink: '',
        nextLink: ''
      },
      limitSelected: { name: 20 },
      limitOptions: [
        { name: 20 },
        { name: 40 },
        { name: 60 },
        { name: 80 },
        { name: 100 },
      ],
    }
  },
  mounted() {
    this.$store.dispatch('dict/GET_STATISTICS')
      .then(response => {
        this.stats = response.data
      })
    this.limitSelected.name = 20
    this.fetchLogs(1, this.limitSelected.name, '')
  },
  methods: {
    toNormalDateTime(myDate) {
      const date = new Date(myDate)
      return new Intl.DateTimeFormat('ru-RU', { dateStyle: 'short', timeStyle: 'short' }).format(date)
    },
    fetchLogs(page = 1, limit) {
      this.$store.dispatch('dict/GET_LOGS', { page, limit })
        .then(response => {
          this.table.items = response.data.results
          this.pagination.pages = response.data.pages
          this.pagination.count = response.data.count
        })
        .catch(error => {
          this.$notify({
            type: 'warn',
            title: 'Внимание!',
            text: `Ошибка: ${ error.message }`
          })
        })
    },
    updateLimiter(data) {
      this.limitSelected.name = data.name
      this.$refs.logsListTable.setLimit(data.name)
      this.fetchLogs(1, data.name)
    },
    clickPaginationCallback(page) {
      this.fetchLogs(page, this.limitSelected.name)
    },
    returnStart() {
      return (this.pagination.page * this.table.items.length) - (this.table.items.length - 1)
    },
    returnEnd() {
      return this.returnStart() + this.table.items.length - 1
    },
    onRowClick(row) {
      this.$router.push({ name: 'stat-unloading-detail', params: { id: row.row.id } })
    },
  }
}
</script>
